
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { reactive } from "vue";
  import { ref, unref } from "vue";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { EnumsConstant } from "@/store/enums/constants";
  import {
    approveMerchantSettlementRequest,
    getMerchantSettlementsRequest, rejectMerchantSettlementRequest
  } from "@/latipay/apis/services/MerchantSettlementsService";
  import { ElMessage } from "element-plus";
  import useClipboard from "vue-clipboard3";
  import {
    ApproveMerchantSettlement,
    RejectMerchantSettlement
  } from "@/latipay/apis/models/merchantSettlement/MerchantSettlementsResponse";
  import { getAccountsRequest } from "@/latipay/apis/services/AccountsService";
  import { ConversionRate } from "@/latipay/apis/models/manualOrder/ManualOrdersResponse";

  export default defineComponent({
    name: "merchantSettlement",
    components: {},
    setup() {
      const store = useStore();

      const id = ref<string | null>(null);
      const merchantId = ref<string | null>(null);
      const walletId = ref<string | null>(null);
      const accountName = ref<string | null>(null);
      const systemAccountId = ref<string | null>(null);
      const selectedCurrency = ref<string | null>(null);
      const failureReason = ref<string | null>(null);
      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);
      const countries = EnumsConstant.CountryCode;
      const currencies = EnumsConstant.CurrencyCode;
      const inputAmount = ref<number | null>(null);
      const inputRate = ref<number | null>(null);
      const accountNumber = ref<string | null>(null);
      const { toClipboard } = useClipboard();
      const visible = ref(false);
      const selectedType = ref("Created");

      const pop = ref();
      const formInline = reactive({
        id: "",
        merchantId: "",
        walletId: "",
        accountName: "",
        accountNumber: "",
        settlementCurrency: ""

      });

      const selectForm = reactive({
        currency: "",
        systemAccountId: ""


      });

      const {
        merchantSettlementsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getMerchantSettlements
      } = getMerchantSettlementsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getMerchantSettlements(
          formInline.id,
          formInline.merchantId,
          formInline.walletId,
          formInline.accountName,
          formInline.accountNumber,
          formInline.settlementCurrency,
          selectedType.value,

          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(merchantSettlementsRef);
      };

      const onSubmit = () => {
        console.log("submit!");
      };

      const refreshCreatedList = async () => {

        console.log(selectedType.value);
        selectedType.value = "Created";
        await getData();
      };
      const refreshCompletedList = async () => {

        console.log(selectedType.value);
        selectedType.value = "Completed";
        await getData();
      };
      const refreshRejectedList = async () => {

        console.log(selectedType.value);
        selectedType.value = "Rejected";
        await getData();
      };

      const openPopup = (record) => {
        console.log(pop.value);

        const approvePop = unref(pop);
        console.log(approvePop);


        // pop.value.visibility = false
      };


      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
        await getAccountsData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };
      const copySlug = async (slug: string) => {
        try {
          await toClipboard(slug);
          ElMessage({
            showClose: true,
            message: "Copy successfully!",
            type: "success"
          });
        } catch (e) {
          console.error(e);
        }
      };

      const successAlert = (message => {
        ElMessage({
          showClose: true,
          message: message,
          type: "success"
        });
      });

      const failAlert = (message => {

        ElMessage.error(message);
      });


      const { patchErrorRef: approveErrorRef, approveMerchantSettlement } = approveMerchantSettlementRequest();
      const { patchErrorRef, rejectMerchantSettlement } = rejectMerchantSettlementRequest();


      const popUpConfirm = async (record) => {


        console.log(record.row);

        if (systemAccountId.value && accountsRef.value) {

           let selectAccount = accountsRef.value.filter( it => it.id === +systemAccountId.value!)[0];
           if (selectAccount.currency === record.row.settlementCurrency) {

             const approveBody = new ApproveMerchantSettlement();
             if (record.row.settlementCurrency !== record.row.wallet.currency) {
               const rateString = new ConversionRate();
               rateString.currency = record.row.wallet.currency + '/'+ record.row.settlementCurrency;
               rateString.rate = inputRate.value!;

               // if (inputRate.value) {
               //   approveBody.settlementAmount = record.row.withdrawalAmount * inputRate.value;
               // } else if (inputAmount.value) {
               //   approveBody.settlementAmount = inputAmount.value;
               // }
               approveBody.settlementAmount = +inputAmount.value!;
                 approveBody.conversionRate = JSON.stringify(rateString);
             } else {
               approveBody.conversionRate = "";
               approveBody.settlementAmount = record.row.settlementAmount;
             }

             if (systemAccountId.value) {
               approveBody.systemAccountId = +systemAccountId.value;
             }

             console.log(approveBody);


             if (approveBody.settlementAmount) {

               await approveMerchantSettlementInfo(record.row.id, approveBody as unknown as ApproveMerchantSettlement);
             }else{
               failAlert("Please input settlement amount!");
             }



           }else{
             failAlert("Please select a " +record.row.settlementCurrency +" system bank!");
           }



        }else{
          failAlert("Please select a system bank!");
        }


      };


      const popUpCancel = async (record) => {
        console.log(record);
        console.log(record.row);
        const rejectBody = new RejectMerchantSettlement();

        if (failureReason.value) {
          rejectBody.failureReason = failureReason.value;
        }else{
          failAlert("Please input a reason");
        }


        await rejectManualOrderInfo(record.row.id, rejectBody as unknown as RejectMerchantSettlement);

      };

      const approveMerchantSettlementInfo = async (id: number, data: ApproveMerchantSettlement) => {
        loading.value = true;
        await approveMerchantSettlement(id, data);

        if (!approveErrorRef.value) {
          loading.value = false;

          successAlert("Approve settlement successfully!");

          await getData();
        } else {
          loading.value = false;
          await getData();
          failAlert("Something wrong happened! Please have a check.");
        }

        console.log(approveErrorRef);

      };
      const rejectManualOrderInfo = async (id: number, data: RejectMerchantSettlement) => {
        loading.value = true;
        await rejectMerchantSettlement(id, data);

        if (!patchErrorRef.value) {
          loading.value = false;

          successAlert("Reject settlement successfully!");
          await getData();

        }else{
          loading.value = false;
          await getData();
          failAlert("Something wrong happened! Please have a check.");
        }

        console.log(patchErrorRef);

      };

      const blurAmount = (async (data) => {
        console.log(data);
        if (inputAmount.value) {
          const rate = +(inputAmount.value / data.withdrawalAmount).toFixed(4);
          inputRate.value = rate;
        }
        // reinitializeComponents();

      });

      const blurRate = (async (data) => {
        console.log("!231");
        if (inputRate.value) {
          inputAmount.value = data.withdrawalAmount * inputRate.value;
        }
        // reinitializeComponents();
      });

      const {
        accountsRef,
        getAccounts
      } = getAccountsRequest();


      const getAccountsData = async () => {
        loading.value = true;

        // await updateQueries();
        await getAccounts(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          1,
          10000
        );
        reinitializeComponents();

        loading.value = false;

        if (accountsRef.value) {
          accountsRef.value = accountsRef.value.filter(it => it.enabled === true);
        }
        console.log(accountsRef);
      };

      return {
        getIllustrationsPath,
        formInline,
        onSubmit,
        countries,
        copySlug,
        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        merchantSettlementsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        id,
        merchantId,
        walletId,
        accountNumber,
        accountName,
        currencies,
        DateTimeUtils,
        popUpConfirm,
        popUpCancel,
        blurAmount,
        blurRate,
        inputAmount,
        inputRate,
        systemAccountId,
        accountsRef,
        visible,
        selectedType,
        openPopup,
        selectedCurrency,
        failureReason,
        selectForm,
        refreshCreatedList,
        refreshCompletedList,
        refreshRejectedList


      };
    },

    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
